.dashboard {
  height: 100%;
  .main-content {
    min-height: calc(100vh - 149px);
    padding: 24px;
    margin-left: 75px;
    margin-top: 65px;
    &--content-only {
      min-height: calc(100vh - 75px);
    }

    @media (max-width: 768px) {
      min-height: calc(100vh - 149px);
      margin-left: 0px;
      padding: 8px;

      &--content-only {
        min-height: calc(100% - 65px);
      }
    }
  }

  &__main {
  }

  .main-header {
    background: white;
    box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.15);
    z-index: 1100; //highest z-index
    display: flex;
    flex-direction: row;
    line-height: initial;

    @media (max-width: 768px) {
      padding-top: 8px !important;
    }

    &__logo-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 230px;
      @media (max-width: 768px) {
        margin-left: 0px;
        padding: 8px;
        width: auto;
        margin-right: auto;
      }
    }

    &__header-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: calc(100% - 250px);
      padding: 0 25px 0 0;
      @media (max-width: 768px) {
        width: 100%;
        padding: 0 10px;
      }
    }

    &__logo {
      height: 60px;
      margin: 14px;
      background: rgba(255, 255, 255, 0.3);
      @media (max-width: 768px) {

        height: 40px;

      }
      @media (max-width: 380px) {
        height: 40px;
      }
    }

    &__trigger {
      padding: 0 24px;
      font-size: 18px;
      line-height: 64px;
      cursor: pointer;
      transition: color 0.3s;

      &:hover {
        color: $primary;
      }
    }

    &__actions {
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__only-mobile {
      display: none;

      @media (max-width: 768px) {
        display: flex;
      }
    }

    .user-notifications {
      margin-right: 30px;
      margin-left: 4px;

      @media (max-width: 768px) {
        margin: 0;
      }
    }
  }

  .menu {
    height: 100%;

    &__item {
      &--no-icon-margin {
        .ant-menu-item-icon {
          margin-right: 0px !important;
        }
      }
    }
  }

  .main-sider {
    width: 250px;

    @media (max-width: 768px) {
      left: 0;
      background: white;

      z-index: 1005;

      //left: 0px !important;
      //bottom: 0 !important;
      //flex: 0 0 80px !important;
      //max-width: 100% !important;
      //min-width: 100% !important;
      //width: 100% !important;
      //z-index: 321 !important;
      //height: 64px !important;
      //top: unset !important;
    }
  }

  &__footer {
    margin-left: 75px;
    @media (max-width: 768px) {
      margin-left: 0;
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: black;
    opacity: 0.1;
    z-index: 1004;
  }

  &__copyright {
    text-align: center;
  }

  &__mobile-menu {
  }
}
