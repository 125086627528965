.auth-login {
  min-height: 100vh;
  background: rgb(234, 242, 255);
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 20px;

  &__card {
    //min-width: 350px;
    border-radius: 15px;
    padding: 15px;
  }

  &__banner {
    border-radius: 15px;
    //margin: 0 60px;
    width: 100%;
  }

  &__logo-container {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* new */
    margin: 30px 0;
  }

  &__logo {
    width: 200px;
    height: auto;
  }

  &__copyrights {
    margin: 30px 0;
  }

  &__submit {
    min-width: 105px;
    margin-right: 8px;
    margin-bottom: 8px;
  }

  .copyrights {
    &__text {
      color: black;
      text-align: center;
    }
  }
}
